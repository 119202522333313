import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <footer className="py-5 w-100 bg-black text-gray-300">
          <div className="text-center">
            {data.site.siteMetadata.title} © {new Date().getFullYear()}
          </div>
        </footer>
      </div>
    </>
  )
}

export default Layout
