import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"

const Header = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <header className="sticky top-0 z-10 bg-white md:h-20 flex items-center justify-between flex-wrap text-gray-900 shadow-md">
      <div className="flex flex-shrink-0 p-2 pl-4">
        <Link to="/">
          <Img className="h-11 w-48" fluid={logo.childImageSharp.fluid} />
        </Link>
        {/* <span style={{ fontFamily: "Sacramento, cursive" }}></span> */}
      </div>
      {/* <label
        htmlFor="menu-toggle"
        className="point-cursor md:hidden text-xl px-4 py-2"
      >
        <i className="fas fa-bars"></i>
      </label>
      <input id="menu-toggle" type="checkbox" className="hidden" />
      <div id="menu" className="hidden relative w-full md:flex md:w-auto">
        <nav className="absolute w-full md:relative md:w-auto left-0 top-0 px-2 z-10 bg-white">
          <ul className="justify-between text-base text-center font-serif md:flex">
            <li className="hover:bg-gray-100">
              <Link to="/" className="px-0 w-full block py-2 md:px-4">
                Blog
              </Link>
            </li>
            <li className="hover:bg-gray-100">
              <Link to="/kava" className="px-0 w-full block py-2 md:px-4">
                Káva
              </Link>
            </li>
            <li className="hover:bg-gray-100">
              <Link to="/rozhovory" className="px-0 w-full block py-2 md:px-4">
                Rozhovory
              </Link>
            </li>
          </ul>
        </nav>
      </div> */}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
